import { call, put, select, takeLatest } from 'redux-saga/effects';
import { DefaultApi } from '../../../generated-sources/openapi';
import { logout } from '../auth/actions';
import { getAuthorizedKey } from '../auth/selectors';
import {
  completeGet,
  completePurchase,
  completePurchaseSample,
  completeUpdate,
  failToGet,
  failToPurchase,
  failToPurchaseSample,
  failToUpdate,
} from './actions';
import actionTypes from './actionTypes';
import { getId } from './selectors';

function* getUseCase(apiClient: DefaultApi) {
  const authorizedKey = yield select(getAuthorizedKey);
  const res = yield call([apiClient, 'getUserMe'], {
    headers: { Authorization: `Bearer ${authorizedKey}` },
  });
  if (res && res.status) {
    if (res.status === 200) {
      yield put(completeGet(res.data));
    } else if (res.status === 403) {
      yield put(logout());
    } else {
      yield put(failToGet());
    }
  } else {
    yield put(failToGet());
  }
}

function* purchaseUseCase(apiClient: DefaultApi, action: any) {
  const { cb, courseId, memo, startDate, stripeToken } = action.payload;
  const id = yield select(getId);
  const authorizedKey = yield select(getAuthorizedKey);
  const res = yield call(
    [apiClient, 'createPurchase'],
    id,
    { courseId, memo: JSON.stringify(memo), startDate, stripeToken },
    {
      headers: { Authorization: `Bearer ${authorizedKey}` },
    },
  );
  if (res && res.status) {
    if (res.status === 200) {
      yield put(completePurchase(res.data));
      cb();
    } else if (res.status === 403) {
      yield put(logout());
    } else {
      yield put(failToPurchase());
    }
  } else {
    yield put(failToPurchase());
  }
}

function* purchaseSampleUseCase(apiClient: DefaultApi, action: any) {
  const { cb, stripeToken } = action.payload;
  const id = yield select(getId);
  const authorizedKey = yield select(getAuthorizedKey);
  const res = yield call(
    [apiClient, 'createPurchase'],
    id,
    { courseId: 4, memo: '', startDate: new Date().toISOString(), stripeToken },
    {
      headers: { Authorization: `Bearer ${authorizedKey}` },
    },
  );
  if (res && res.status) {
    if (res.status === 200) {
      yield put(completePurchaseSample(res.data));
      cb();
    } else if (res.status === 403) {
      yield put(logout());
    } else {
      yield put(failToPurchaseSample());
    }
  } else {
    yield put(failToPurchaseSample());
  }
}

function* updateUseCase(apiClient: DefaultApi, action: any) {
  const { cb, params } = action.payload;
  const id = yield select(getId);
  const authorizedKey = yield select(getAuthorizedKey);
  const res = yield call([apiClient, 'updateUser'], id, params, {
    headers: { Authorization: `Bearer ${authorizedKey}` },
  });
  if (res && res.status) {
    if (res.status === 200) {
      yield put(completeUpdate(res.data));
      cb();
    } else if (res.status === 403) {
      yield put(logout());
    } else {
      yield put(failToUpdate());
    }
  } else {
    yield put(failToUpdate());
  }
}

export default function* userSaga(apiClient: DefaultApi) {
  yield takeLatest(actionTypes.GET_REQUEST, getUseCase, apiClient);
  yield takeLatest(actionTypes.PURCHASE_REQUEST, purchaseUseCase, apiClient);
  yield takeLatest(
    actionTypes.PURCHASE_SAMPLE_REQUEST,
    purchaseSampleUseCase,
    apiClient,
  );
  yield takeLatest(actionTypes.UPDATE_REQUEST, updateUseCase, apiClient);
}
