module.exports = [{
      plugin: require('/home/circleci/ikujicare/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://ikujicare.jp"},
    },{
      plugin: require('/home/circleci/ikujicare/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WP33BV4"},
    },{
      plugin: require('/home/circleci/ikujicare/packages/front/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
