export default {
  GET_COMPLETE: '@FRONT/USER/GET_COMPLETE',
  GET_FAIL: '@FRONT/USER/GET_FAIL',
  GET_REQUEST: '@FRONT/USER/GET_REQUEST',
  PURCHASE_COMPLETE: '@FRONT/USER/PURCHASE_COMPLETE',
  PURCHASE_FAIL: '@FRONT/USER/PURCHASE_FAIL',
  PURCHASE_REQUEST: '@FRONT/USER/PURCHASE_REQUEST',
  PURCHASE_SAMPLE_COMPLETE: '@FRONT/USER/PURCHASE_SAMPLE_COMPLETE',
  PURCHASE_SAMPLE_FAIL: '@FRONT/USER/PURCHASE_SAMPLE_FAIL',
  PURCHASE_SAMPLE_REQUEST: '@FRONT/USER/PURCHASE_SAMPLE_REQUEST',
  UPDATE_COMPLETE: '@FRONT/USER/UPDATE_COMPLETE',
  UPDATE_FAIL: '@FRONT/USER/UPDATE_FAIL',
  UPDATE_REQUEST: '@FRONT/USER/UPDATE_REQUEST',
};
