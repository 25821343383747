import { UserProperties } from '../../../generated-sources/openapi';
import authActionTypes from '../auth/actionTypes';
import actionTypes from './actionTypes';

export const initialState: State = {
  birthday: '',
  createdAt: '',
  email: '',
  id: 0,
  lineId: '',
  lineName: '',
  linePictureUrl: '',
  name: '',
  nameKana: '',
  phoneNumber: '',
  purchases: [],
  stripeCustomerId: '',
  updatedAt: '',
};

export type State = UserProperties;
type Action = any;

export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case actionTypes.GET_COMPLETE:
    case actionTypes.PURCHASE_COMPLETE:
    case actionTypes.PURCHASE_SAMPLE_COMPLETE:
    case actionTypes.UPDATE_COMPLETE: {
      return { ...state, ...action.payload };
    }
    case authActionTypes.LOGIN_COMPLETE:
      return { ...state, ...action.payload.user };
    case authActionTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
};
