import { UserProperties } from '../../../generated-sources/openapi';
import actionTypes from './actionTypes';

export const initialState: State = {};

export interface State {
  authorizedKey?: string;
  user?: UserProperties;
}
type Action = any;

export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case actionTypes.LOGIN_COMPLETE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
};
