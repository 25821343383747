import axios from 'axios';
import { spawn } from 'redux-saga/effects';
import { DefaultApi } from '../../generated-sources/openapi';
import authSaga from './auth/saga';
import userSaga from './user/saga';

const axiosInstance = axios.create({
  timeout: 10000,
});
axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return error.response;
  },
);
const apiClient = new DefaultApi({}, process.env.API_URL, axiosInstance);

export default function* saga() {
  yield spawn(authSaga, apiClient);
  yield spawn(userSaga, apiClient);
}
