// tslint:disable
/// <reference path="./custom.d.ts" />
/**
 * 育児ケアオンライン API ドキュメント
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as url from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Admin
 */
export interface Admin {
    /**
     * 
     * @type {AdminProperties}
     * @memberof Admin
     */
    admin: AdminProperties;
}

/**
 * 
 * @export
 * @interface AdminLoginRequest
 */
export interface AdminLoginRequest {
    /**
     * Admin Email
     * @type {string}
     * @memberof AdminLoginRequest
     */
    email: string;
    /**
     * Admin Password
     * @type {string}
     * @memberof AdminLoginRequest
     */
    password: string;
}

/**
 * 
 * @export
 * @interface AdminLoginResponse
 */
export interface AdminLoginResponse {
    /**
     * Admin ID
     * @type {number}
     * @memberof AdminLoginResponse
     */
    id: number;
    /**
     * Admin Email
     * @type {string}
     * @memberof AdminLoginResponse
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof AdminLoginResponse
     */
    authorizedKey: string;
}

/**
 * 
 * @export
 * @interface AdminProperties
 */
export interface AdminProperties {
    /**
     * 
     * @type {number}
     * @memberof AdminProperties
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AdminProperties
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminProperties
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof AdminProperties
     */
    updatedAt: string;
}

/**
 * 
 * @export
 * @interface Admins
 */
export interface Admins {
    /**
     * 
     * @type {Array<AdminProperties>}
     * @memberof Admins
     */
    admins: Array<AdminProperties>;
}

/**
 * 
 * @export
 * @interface AssignSupporterRequest
 */
export interface AssignSupporterRequest {
    /**
     * サポーター ID
     * @type {number}
     * @memberof AssignSupporterRequest
     */
    supporterId: number;
}

/**
 * 
 * @export
 * @interface Course
 */
export interface Course {
    /**
     * 
     * @type {CourseProperties}
     * @memberof Course
     */
    course: CourseProperties;
}

/**
 * 
 * @export
 * @interface CourseProperties
 */
export interface CourseProperties {
    /**
     * コースの ID
     * @type {number}
     * @memberof CourseProperties
     */
    id: number;
    /**
     * Course Name
     * @type {string}
     * @memberof CourseProperties
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CourseProperties
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof CourseProperties
     */
    updatedAt: string;
}

/**
 * 
 * @export
 * @interface CourseRequest
 */
export interface CourseRequest {
    /**
     * 
     * @type {Array<CourseProperties>}
     * @memberof CourseRequest
     */
    courses: Array<CourseProperties>;
}

/**
 * 
 * @export
 * @interface Courses
 */
export interface Courses {
    /**
     * 
     * @type {Array<CourseProperties>}
     * @memberof Courses
     */
    courses: Array<CourseProperties>;
}

/**
 * 
 * @export
 * @interface ErrorProperties
 */
export interface ErrorProperties {
    /**
     * 
     * @type {string}
     * @memberof ErrorProperties
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorProperties
     */
    detail: string;
}

/**
 * 
 * @export
 * @interface Errors
 */
export interface Errors {
    /**
     * 
     * @type {Array<ErrorProperties>}
     * @memberof Errors
     */
    errors: Array<ErrorProperties>;
}

/**
 * 
 * @export
 * @interface LineLoginRequest
 */
export interface LineLoginRequest {
    /**
     * LINE ログイン認可コード
     * @type {string}
     * @memberof LineLoginRequest
     */
    code: string;
    /**
     * LINE ログイン state パラメータ
     * @type {string}
     * @memberof LineLoginRequest
     */
    state: string;
}

/**
 * 
 * @export
 * @interface LineLoginResponse
 */
export interface LineLoginResponse {
    /**
     * 
     * @type {string}
     * @memberof LineLoginResponse
     */
    authorizedKey: string;
    /**
     * 
     * @type {UserProperties}
     * @memberof LineLoginResponse
     */
    user: UserProperties;
}

/**
 * 
 * @export
 * @interface LineMessageRequest
 */
export interface LineMessageRequest {
    /**
     * LINE メッセージのテキスト
     * @type {string}
     * @memberof LineMessageRequest
     */
    message: string;
}

/**
 * 
 * @export
 * @interface LineMessageResponse
 */
export interface LineMessageResponse {
    /**
     * LINE メッセージのテキスト
     * @type {string}
     * @memberof LineMessageResponse
     */
    message: string;
}

/**
 * 
 * @export
 * @interface Purchase
 */
export interface Purchase {
    /**
     * 
     * @type {PurchaseProperties}
     * @memberof Purchase
     */
    purchase: PurchaseProperties;
}

/**
 * 
 * @export
 * @interface PurchaseProperties
 */
export interface PurchaseProperties {
    /**
     * 
     * @type {number}
     * @memberof PurchaseProperties
     */
    id: number;
    /**
     * 
     * @type {UserProperties}
     * @memberof PurchaseProperties
     */
    user: UserProperties;
    /**
     * 
     * @type {CourseProperties}
     * @memberof PurchaseProperties
     */
    course: CourseProperties;
    /**
     * 利用開始日
     * @type {string}
     * @memberof PurchaseProperties
     */
    startDate: string;
    /**
     * 
     * @type {number}
     * @memberof PurchaseProperties
     */
    charge: number;
    /**
     * メモ
     * @type {string}
     * @memberof PurchaseProperties
     */
    memo: string;
    /**
     * 
     * @type {SupporterProperties}
     * @memberof PurchaseProperties
     */
    assignedSupporter?: SupporterProperties;
    /**
     * 
     * @type {string}
     * @memberof PurchaseProperties
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseProperties
     */
    updatedAt: string;
}

/**
 * 
 * @export
 * @interface PurchaseRequest
 */
export interface PurchaseRequest {
    /**
     * コースの ID
     * @type {number}
     * @memberof PurchaseRequest
     */
    courseId: number;
    /**
     * メモ
     * @type {string}
     * @memberof PurchaseRequest
     */
    memo: string;
    /**
     * 利用開始日
     * @type {string}
     * @memberof PurchaseRequest
     */
    startDate: string;
    /**
     * Stripe の Token
     * @type {string}
     * @memberof PurchaseRequest
     */
    stripeToken: string;
}

/**
 * 
 * @export
 * @interface Purchases
 */
export interface Purchases {
    /**
     * 
     * @type {Array<PurchaseProperties>}
     * @memberof Purchases
     */
    purchases: Array<PurchaseProperties>;
}

/**
 * 
 * @export
 * @interface Supporter
 */
export interface Supporter {
    /**
     * 
     * @type {SupporterProperties}
     * @memberof Supporter
     */
    supporter: SupporterProperties;
}

/**
 * 
 * @export
 * @interface SupporterProperties
 */
export interface SupporterProperties {
    /**
     * 
     * @type {number}
     * @memberof SupporterProperties
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof SupporterProperties
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof SupporterProperties
     */
    lineWorksUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof SupporterProperties
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof SupporterProperties
     */
    updatedAt: string;
}

/**
 * 
 * @export
 * @interface Supporters
 */
export interface Supporters {
    /**
     * 
     * @type {Array<SupporterProperties>}
     * @memberof Supporters
     */
    supporters: Array<SupporterProperties>;
}

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {UserProperties}
     * @memberof User
     */
    user: UserProperties;
}

/**
 * 
 * @export
 * @interface UserProperties
 */
export interface UserProperties {
    /**
     * ユーザ ID
     * @type {number}
     * @memberof UserProperties
     */
    id: number;
    /**
     * ユーザの名前
     * @type {string}
     * @memberof UserProperties
     */
    name: string;
    /**
     * ユーザの名前（かな）
     * @type {string}
     * @memberof UserProperties
     */
    nameKana: string;
    /**
     * ユーザの生年月日
     * @type {string}
     * @memberof UserProperties
     */
    birthday: string;
    /**
     * ユーザの LINE ID
     * @type {string}
     * @memberof UserProperties
     */
    lineId: string;
    /**
     * ユーザの LINE 名
     * @type {string}
     * @memberof UserProperties
     */
    lineName: string;
    /**
     * ユーザの LINE アイコン URL
     * @type {string}
     * @memberof UserProperties
     */
    linePictureUrl: string;
    /**
     * ユーザの電話番号
     * @type {string}
     * @memberof UserProperties
     */
    phoneNumber: string;
    /**
     * ユーザのメールアドレス
     * @type {string}
     * @memberof UserProperties
     */
    email: string;
    /**
     * ユーザの Stripe Customer ID
     * @type {string}
     * @memberof UserProperties
     */
    stripeCustomerId: string;
    /**
     * 
     * @type {Array<PurchaseProperties>}
     * @memberof UserProperties
     */
    purchases: Array<PurchaseProperties>;
    /**
     * 
     * @type {string}
     * @memberof UserProperties
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof UserProperties
     */
    updatedAt: string;
}

/**
 * 
 * @export
 * @interface UserUpdateRequest
 */
export interface UserUpdateRequest {
    /**
     * ユーザの名前
     * @type {string}
     * @memberof UserUpdateRequest
     */
    name?: string;
    /**
     * ユーザの名前（かな）
     * @type {string}
     * @memberof UserUpdateRequest
     */
    nameKana?: string;
    /**
     * ユーザの生年月日
     * @type {string}
     * @memberof UserUpdateRequest
     */
    birthday?: string;
    /**
     * ユーザの電話番号
     * @type {string}
     * @memberof UserUpdateRequest
     */
    phoneNumber?: string;
    /**
     * ユーザのメールアドレス
     * @type {string}
     * @memberof UserUpdateRequest
     */
    email?: string;
    /**
     * ユーザの居住地
     * @type {string}
     * @memberof UserUpdateRequest
     */
    residentialArea?: string;
    /**
     * ユーザの登録理由
     * @type {string}
     * @memberof UserUpdateRequest
     */
    registrationReason?: string;
}

/**
 * 
 * @export
 * @interface Users
 */
export interface Users {
    /**
     * 
     * @type {Array<UserProperties>}
     * @memberof Users
     */
    users: Array<UserProperties>;
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 管理者ログイン
         * @summary /admin/login
         * @param {AdminLoginRequest} adminLoginRequest 管理者ログイン
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLogin(adminLoginRequest: AdminLoginRequest, options: any = {}): RequestArgs {
            // verify required parameter 'adminLoginRequest' is not null or undefined
            if (adminLoginRequest === null || adminLoginRequest === undefined) {
                throw new RequiredError('adminLoginRequest','Required parameter adminLoginRequest was null or undefined when calling adminLogin.');
            }
            const localVarPath = `/admin/login`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"AdminLoginRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(adminLoginRequest !== undefined ? adminLoginRequest : {}) : (adminLoginRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 担当サポーターを設定する
         * @summary /admin/purchases/{purchaseId}/assign
         * @param {number} purchaseId 購入情報の ID
         * @param {AssignSupporterRequest} assignSupporterRequest 購入へのサポーター設定
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignSupporterToPurchase(purchaseId: number, assignSupporterRequest: AssignSupporterRequest, options: any = {}): RequestArgs {
            // verify required parameter 'purchaseId' is not null or undefined
            if (purchaseId === null || purchaseId === undefined) {
                throw new RequiredError('purchaseId','Required parameter purchaseId was null or undefined when calling assignSupporterToPurchase.');
            }
            // verify required parameter 'assignSupporterRequest' is not null or undefined
            if (assignSupporterRequest === null || assignSupporterRequest === undefined) {
                throw new RequiredError('assignSupporterRequest','Required parameter assignSupporterRequest was null or undefined when calling assignSupporterToPurchase.');
            }
            const localVarPath = `/admin/purchases/{purchaseId}/assign`
                .replace(`{${"purchaseId"}}`, encodeURIComponent(String(purchaseId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"AssignSupporterRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(assignSupporterRequest !== undefined ? assignSupporterRequest : {}) : (assignSupporterRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * コースを申し込む
         * @summary /users/{userId}/purchases
         * @param {number} userId ユーザの ID
         * @param {PurchaseRequest} purchaseRequest コース購入
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPurchase(userId: number, purchaseRequest: PurchaseRequest, options: any = {}): RequestArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling createPurchase.');
            }
            // verify required parameter 'purchaseRequest' is not null or undefined
            if (purchaseRequest === null || purchaseRequest === undefined) {
                throw new RequiredError('purchaseRequest','Required parameter purchaseRequest was null or undefined when calling createPurchase.');
            }
            const localVarPath = `/users/{userId}/purchases`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"PurchaseRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(purchaseRequest !== undefined ? purchaseRequest : {}) : (purchaseRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * コースをすべて取得する
         * @summary /admin/courses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminCourse(options: any = {}): RequestArgs {
            const localVarPath = `/admin/courses`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * コースを作成する
         * @summary /admin/courses
         * @param {CourseRequest} courseRequest コース作成
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminCourses(courseRequest: CourseRequest, options: any = {}): RequestArgs {
            // verify required parameter 'courseRequest' is not null or undefined
            if (courseRequest === null || courseRequest === undefined) {
                throw new RequiredError('courseRequest','Required parameter courseRequest was null or undefined when calling getAdminCourses.');
            }
            const localVarPath = `/admin/courses`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"CourseRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(courseRequest !== undefined ? courseRequest : {}) : (courseRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 購入情報を取得する
         * @summary /admin/purchases/{purchaseId}
         * @param {number} purchaseId 購入情報の ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminPurchase(purchaseId: number, options: any = {}): RequestArgs {
            // verify required parameter 'purchaseId' is not null or undefined
            if (purchaseId === null || purchaseId === undefined) {
                throw new RequiredError('purchaseId','Required parameter purchaseId was null or undefined when calling getAdminPurchase.');
            }
            const localVarPath = `/admin/purchases/{purchaseId}`
                .replace(`{${"purchaseId"}}`, encodeURIComponent(String(purchaseId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 購入情報をすべて取得する
         * @summary /admin/purchases
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminPurchases(options: any = {}): RequestArgs {
            const localVarPath = `/admin/purchases`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * サポーター情報を取得する
         * @summary /admin/supporters/{supporterId}
         * @param {number} supporterId サポーター ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminSupporter(supporterId: number, options: any = {}): RequestArgs {
            // verify required parameter 'supporterId' is not null or undefined
            if (supporterId === null || supporterId === undefined) {
                throw new RequiredError('supporterId','Required parameter supporterId was null or undefined when calling getAdminSupporter.');
            }
            const localVarPath = `/admin/supporters/{supporterId}`
                .replace(`{${"supporterId"}}`, encodeURIComponent(String(supporterId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * サポーター情報をすべて取得する
         * @summary /admin/supporters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminSupporters(options: any = {}): RequestArgs {
            const localVarPath = `/admin/supporters`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザを取得する
         * @summary /admin/users/{userId}
         * @param {number} userId ユーザの ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminUser(userId: number, options: any = {}): RequestArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getAdminUser.');
            }
            const localVarPath = `/admin/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザをすべて取得する
         * @summary /admin/users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminUsers(options: any = {}): RequestArgs {
            const localVarPath = `/admin/users`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 管理者をすべて取得する
         * @summary /admins
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdmins(options: any = {}): RequestArgs {
            const localVarPath = `/admins`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * コースを取得する
         * @summary /courses/{courseId}
         * @param {number} courseId コースの ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourse(courseId: number, options: any = {}): RequestArgs {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling getCourse.');
            }
            const localVarPath = `/courses/{courseId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザ情報を取得する
         * @summary /users/me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserMe(options: any = {}): RequestArgs {
            const localVarPath = `/users/me`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * LINE ログイン
         * @summary /auth/line/login
         * @param {LineLoginRequest} lineLoginRequest LINE ログイン
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lineLogin(lineLoginRequest: LineLoginRequest, options: any = {}): RequestArgs {
            // verify required parameter 'lineLoginRequest' is not null or undefined
            if (lineLoginRequest === null || lineLoginRequest === undefined) {
                throw new RequiredError('lineLoginRequest','Required parameter lineLoginRequest was null or undefined when calling lineLogin.');
            }
            const localVarPath = `/auth/line/login`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"LineLoginRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(lineLoginRequest !== undefined ? lineLoginRequest : {}) : (lineLoginRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザに LINE メッセージを送る
         * @summary /admin/users/{userId}/send_line_message
         * @param {number} userId ユーザの ID
         * @param {LineMessageRequest} lineMessageRequest LINE メッセージ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendLineMessageToAdminUser(userId: number, lineMessageRequest: LineMessageRequest, options: any = {}): RequestArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling sendLineMessageToAdminUser.');
            }
            // verify required parameter 'lineMessageRequest' is not null or undefined
            if (lineMessageRequest === null || lineMessageRequest === undefined) {
                throw new RequiredError('lineMessageRequest','Required parameter lineMessageRequest was null or undefined when calling sendLineMessageToAdminUser.');
            }
            const localVarPath = `/admin/users/{userId}/send_line_message`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"LineMessageRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(lineMessageRequest !== undefined ? lineMessageRequest : {}) : (lineMessageRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザ情報を更新する
         * @summary /users/{userId}
         * @param {number} userId ユーザの ID
         * @param {UserUpdateRequest} userUpdateRequest ユーザ情報更新
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(userId: number, userUpdateRequest: UserUpdateRequest, options: any = {}): RequestArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling updateUser.');
            }
            // verify required parameter 'userUpdateRequest' is not null or undefined
            if (userUpdateRequest === null || userUpdateRequest === undefined) {
                throw new RequiredError('userUpdateRequest','Required parameter userUpdateRequest was null or undefined when calling updateUser.');
            }
            const localVarPath = `/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"UserUpdateRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(userUpdateRequest !== undefined ? userUpdateRequest : {}) : (userUpdateRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 管理者ログイン
         * @summary /admin/login
         * @param {AdminLoginRequest} adminLoginRequest 管理者ログイン
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLogin(adminLoginRequest: AdminLoginRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminLoginResponse> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).adminLogin(adminLoginRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 担当サポーターを設定する
         * @summary /admin/purchases/{purchaseId}/assign
         * @param {number} purchaseId 購入情報の ID
         * @param {AssignSupporterRequest} assignSupporterRequest 購入へのサポーター設定
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignSupporterToPurchase(purchaseId: number, assignSupporterRequest: AssignSupporterRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Purchase> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).assignSupporterToPurchase(purchaseId, assignSupporterRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * コースを申し込む
         * @summary /users/{userId}/purchases
         * @param {number} userId ユーザの ID
         * @param {PurchaseRequest} purchaseRequest コース購入
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPurchase(userId: number, purchaseRequest: PurchaseRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Purchase> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createPurchase(userId, purchaseRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * コースをすべて取得する
         * @summary /admin/courses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminCourse(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Courses> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getAdminCourse(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * コースを作成する
         * @summary /admin/courses
         * @param {CourseRequest} courseRequest コース作成
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminCourses(courseRequest: CourseRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Course> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getAdminCourses(courseRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 購入情報を取得する
         * @summary /admin/purchases/{purchaseId}
         * @param {number} purchaseId 購入情報の ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminPurchase(purchaseId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Purchase> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getAdminPurchase(purchaseId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 購入情報をすべて取得する
         * @summary /admin/purchases
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminPurchases(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Purchases> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getAdminPurchases(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * サポーター情報を取得する
         * @summary /admin/supporters/{supporterId}
         * @param {number} supporterId サポーター ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminSupporter(supporterId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Supporter> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getAdminSupporter(supporterId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * サポーター情報をすべて取得する
         * @summary /admin/supporters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminSupporters(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Supporters> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getAdminSupporters(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * ユーザを取得する
         * @summary /admin/users/{userId}
         * @param {number} userId ユーザの ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminUser(userId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<User> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getAdminUser(userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * ユーザをすべて取得する
         * @summary /admin/users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminUsers(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Users> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getAdminUsers(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 管理者をすべて取得する
         * @summary /admins
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdmins(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Admins> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getAdmins(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * コースを取得する
         * @summary /courses/{courseId}
         * @param {number} courseId コースの ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourse(courseId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Course> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getCourse(courseId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * ユーザ情報を取得する
         * @summary /users/me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserMe(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<User> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getUserMe(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * LINE ログイン
         * @summary /auth/line/login
         * @param {LineLoginRequest} lineLoginRequest LINE ログイン
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lineLogin(lineLoginRequest: LineLoginRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LineLoginResponse> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).lineLogin(lineLoginRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * ユーザに LINE メッセージを送る
         * @summary /admin/users/{userId}/send_line_message
         * @param {number} userId ユーザの ID
         * @param {LineMessageRequest} lineMessageRequest LINE メッセージ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendLineMessageToAdminUser(userId: number, lineMessageRequest: LineMessageRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LineMessageResponse> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).sendLineMessageToAdminUser(userId, lineMessageRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * ユーザ情報を更新する
         * @summary /users/{userId}
         * @param {number} userId ユーザの ID
         * @param {UserUpdateRequest} userUpdateRequest ユーザ情報更新
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(userId: number, userUpdateRequest: UserUpdateRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<User> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateUser(userId, userUpdateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 管理者ログイン
         * @summary /admin/login
         * @param {AdminLoginRequest} adminLoginRequest 管理者ログイン
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLogin(adminLoginRequest: AdminLoginRequest, options?: any) {
            return DefaultApiFp(configuration).adminLogin(adminLoginRequest, options)(axios, basePath);
        },
        /**
         * 担当サポーターを設定する
         * @summary /admin/purchases/{purchaseId}/assign
         * @param {number} purchaseId 購入情報の ID
         * @param {AssignSupporterRequest} assignSupporterRequest 購入へのサポーター設定
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignSupporterToPurchase(purchaseId: number, assignSupporterRequest: AssignSupporterRequest, options?: any) {
            return DefaultApiFp(configuration).assignSupporterToPurchase(purchaseId, assignSupporterRequest, options)(axios, basePath);
        },
        /**
         * コースを申し込む
         * @summary /users/{userId}/purchases
         * @param {number} userId ユーザの ID
         * @param {PurchaseRequest} purchaseRequest コース購入
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPurchase(userId: number, purchaseRequest: PurchaseRequest, options?: any) {
            return DefaultApiFp(configuration).createPurchase(userId, purchaseRequest, options)(axios, basePath);
        },
        /**
         * コースをすべて取得する
         * @summary /admin/courses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminCourse(options?: any) {
            return DefaultApiFp(configuration).getAdminCourse(options)(axios, basePath);
        },
        /**
         * コースを作成する
         * @summary /admin/courses
         * @param {CourseRequest} courseRequest コース作成
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminCourses(courseRequest: CourseRequest, options?: any) {
            return DefaultApiFp(configuration).getAdminCourses(courseRequest, options)(axios, basePath);
        },
        /**
         * 購入情報を取得する
         * @summary /admin/purchases/{purchaseId}
         * @param {number} purchaseId 購入情報の ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminPurchase(purchaseId: number, options?: any) {
            return DefaultApiFp(configuration).getAdminPurchase(purchaseId, options)(axios, basePath);
        },
        /**
         * 購入情報をすべて取得する
         * @summary /admin/purchases
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminPurchases(options?: any) {
            return DefaultApiFp(configuration).getAdminPurchases(options)(axios, basePath);
        },
        /**
         * サポーター情報を取得する
         * @summary /admin/supporters/{supporterId}
         * @param {number} supporterId サポーター ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminSupporter(supporterId: number, options?: any) {
            return DefaultApiFp(configuration).getAdminSupporter(supporterId, options)(axios, basePath);
        },
        /**
         * サポーター情報をすべて取得する
         * @summary /admin/supporters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminSupporters(options?: any) {
            return DefaultApiFp(configuration).getAdminSupporters(options)(axios, basePath);
        },
        /**
         * ユーザを取得する
         * @summary /admin/users/{userId}
         * @param {number} userId ユーザの ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminUser(userId: number, options?: any) {
            return DefaultApiFp(configuration).getAdminUser(userId, options)(axios, basePath);
        },
        /**
         * ユーザをすべて取得する
         * @summary /admin/users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminUsers(options?: any) {
            return DefaultApiFp(configuration).getAdminUsers(options)(axios, basePath);
        },
        /**
         * 管理者をすべて取得する
         * @summary /admins
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdmins(options?: any) {
            return DefaultApiFp(configuration).getAdmins(options)(axios, basePath);
        },
        /**
         * コースを取得する
         * @summary /courses/{courseId}
         * @param {number} courseId コースの ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourse(courseId: number, options?: any) {
            return DefaultApiFp(configuration).getCourse(courseId, options)(axios, basePath);
        },
        /**
         * ユーザ情報を取得する
         * @summary /users/me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserMe(options?: any) {
            return DefaultApiFp(configuration).getUserMe(options)(axios, basePath);
        },
        /**
         * LINE ログイン
         * @summary /auth/line/login
         * @param {LineLoginRequest} lineLoginRequest LINE ログイン
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lineLogin(lineLoginRequest: LineLoginRequest, options?: any) {
            return DefaultApiFp(configuration).lineLogin(lineLoginRequest, options)(axios, basePath);
        },
        /**
         * ユーザに LINE メッセージを送る
         * @summary /admin/users/{userId}/send_line_message
         * @param {number} userId ユーザの ID
         * @param {LineMessageRequest} lineMessageRequest LINE メッセージ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendLineMessageToAdminUser(userId: number, lineMessageRequest: LineMessageRequest, options?: any) {
            return DefaultApiFp(configuration).sendLineMessageToAdminUser(userId, lineMessageRequest, options)(axios, basePath);
        },
        /**
         * ユーザ情報を更新する
         * @summary /users/{userId}
         * @param {number} userId ユーザの ID
         * @param {UserUpdateRequest} userUpdateRequest ユーザ情報更新
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(userId: number, userUpdateRequest: UserUpdateRequest, options?: any) {
            return DefaultApiFp(configuration).updateUser(userId, userUpdateRequest, options)(axios, basePath);
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 管理者ログイン
     * @summary /admin/login
     * @param {AdminLoginRequest} adminLoginRequest 管理者ログイン
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminLogin(adminLoginRequest: AdminLoginRequest, options?: any) {
        return DefaultApiFp(this.configuration).adminLogin(adminLoginRequest, options)(this.axios, this.basePath);
    }

    /**
     * 担当サポーターを設定する
     * @summary /admin/purchases/{purchaseId}/assign
     * @param {number} purchaseId 購入情報の ID
     * @param {AssignSupporterRequest} assignSupporterRequest 購入へのサポーター設定
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public assignSupporterToPurchase(purchaseId: number, assignSupporterRequest: AssignSupporterRequest, options?: any) {
        return DefaultApiFp(this.configuration).assignSupporterToPurchase(purchaseId, assignSupporterRequest, options)(this.axios, this.basePath);
    }

    /**
     * コースを申し込む
     * @summary /users/{userId}/purchases
     * @param {number} userId ユーザの ID
     * @param {PurchaseRequest} purchaseRequest コース購入
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createPurchase(userId: number, purchaseRequest: PurchaseRequest, options?: any) {
        return DefaultApiFp(this.configuration).createPurchase(userId, purchaseRequest, options)(this.axios, this.basePath);
    }

    /**
     * コースをすべて取得する
     * @summary /admin/courses
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAdminCourse(options?: any) {
        return DefaultApiFp(this.configuration).getAdminCourse(options)(this.axios, this.basePath);
    }

    /**
     * コースを作成する
     * @summary /admin/courses
     * @param {CourseRequest} courseRequest コース作成
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAdminCourses(courseRequest: CourseRequest, options?: any) {
        return DefaultApiFp(this.configuration).getAdminCourses(courseRequest, options)(this.axios, this.basePath);
    }

    /**
     * 購入情報を取得する
     * @summary /admin/purchases/{purchaseId}
     * @param {number} purchaseId 購入情報の ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAdminPurchase(purchaseId: number, options?: any) {
        return DefaultApiFp(this.configuration).getAdminPurchase(purchaseId, options)(this.axios, this.basePath);
    }

    /**
     * 購入情報をすべて取得する
     * @summary /admin/purchases
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAdminPurchases(options?: any) {
        return DefaultApiFp(this.configuration).getAdminPurchases(options)(this.axios, this.basePath);
    }

    /**
     * サポーター情報を取得する
     * @summary /admin/supporters/{supporterId}
     * @param {number} supporterId サポーター ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAdminSupporter(supporterId: number, options?: any) {
        return DefaultApiFp(this.configuration).getAdminSupporter(supporterId, options)(this.axios, this.basePath);
    }

    /**
     * サポーター情報をすべて取得する
     * @summary /admin/supporters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAdminSupporters(options?: any) {
        return DefaultApiFp(this.configuration).getAdminSupporters(options)(this.axios, this.basePath);
    }

    /**
     * ユーザを取得する
     * @summary /admin/users/{userId}
     * @param {number} userId ユーザの ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAdminUser(userId: number, options?: any) {
        return DefaultApiFp(this.configuration).getAdminUser(userId, options)(this.axios, this.basePath);
    }

    /**
     * ユーザをすべて取得する
     * @summary /admin/users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAdminUsers(options?: any) {
        return DefaultApiFp(this.configuration).getAdminUsers(options)(this.axios, this.basePath);
    }

    /**
     * 管理者をすべて取得する
     * @summary /admins
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAdmins(options?: any) {
        return DefaultApiFp(this.configuration).getAdmins(options)(this.axios, this.basePath);
    }

    /**
     * コースを取得する
     * @summary /courses/{courseId}
     * @param {number} courseId コースの ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCourse(courseId: number, options?: any) {
        return DefaultApiFp(this.configuration).getCourse(courseId, options)(this.axios, this.basePath);
    }

    /**
     * ユーザ情報を取得する
     * @summary /users/me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUserMe(options?: any) {
        return DefaultApiFp(this.configuration).getUserMe(options)(this.axios, this.basePath);
    }

    /**
     * LINE ログイン
     * @summary /auth/line/login
     * @param {LineLoginRequest} lineLoginRequest LINE ログイン
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public lineLogin(lineLoginRequest: LineLoginRequest, options?: any) {
        return DefaultApiFp(this.configuration).lineLogin(lineLoginRequest, options)(this.axios, this.basePath);
    }

    /**
     * ユーザに LINE メッセージを送る
     * @summary /admin/users/{userId}/send_line_message
     * @param {number} userId ユーザの ID
     * @param {LineMessageRequest} lineMessageRequest LINE メッセージ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public sendLineMessageToAdminUser(userId: number, lineMessageRequest: LineMessageRequest, options?: any) {
        return DefaultApiFp(this.configuration).sendLineMessageToAdminUser(userId, lineMessageRequest, options)(this.axios, this.basePath);
    }

    /**
     * ユーザ情報を更新する
     * @summary /users/{userId}
     * @param {number} userId ユーザの ID
     * @param {UserUpdateRequest} userUpdateRequest ユーザ情報更新
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateUser(userId: number, userUpdateRequest: UserUpdateRequest, options?: any) {
        return DefaultApiFp(this.configuration).updateUser(userId, userUpdateRequest, options)(this.axios, this.basePath);
    }

}


