import authActionTypes from '../auth/actionTypes';
import actionTypes from './actionTypes';

export const initialState: State = {
  open: false,
};

export interface State {
  open: boolean;
}
type Action = any;

export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case actionTypes.OPEN: {
      return { ...state, open: true };
    }
    case actionTypes.CLOSE:
    case authActionTypes.LOGIN_REQUEST: {
      return { ...state, open: false };
    }
    default:
      return state;
  }
};
