import {
  LineLoginRequest,
  LineLoginResponse,
} from '../../../generated-sources/openapi';
import actionTypes from './actionTypes';

export const cancelLogin = () => ({
  type: actionTypes.LOGIN_CANCEL,
});

export const failToLogin = () => ({
  type: actionTypes.LOGIN_FAIL,
});

export const login = ({ code, state }: LineLoginRequest) => ({
  payload: { code, state },
  type: actionTypes.LOGIN_REQUEST,
});

export const logout = () => ({
  type: actionTypes.LOGOUT,
});

export const completeLogin = ({ authorizedKey, user }: LineLoginResponse) => ({
  payload: {
    authorizedKey,
    user,
  },
  type: actionTypes.LOGIN_COMPLETE,
});
