import { createSelector } from 'reselect';
import { RootState } from '../rootReducer';
import { State as AuthState } from './reducer';

export const authSelector = (state: RootState): AuthState => state.auth;

export const getAuthorizedKey = createSelector<
  RootState,
  AuthState,
  AuthState['authorizedKey']
>(
  authSelector,
  auth => auth.authorizedKey,
);

export const getIsLoggedIn = createSelector<
  RootState,
  AuthState['authorizedKey'],
  boolean
>(
  getAuthorizedKey,
  authorizedKey => !!authorizedKey,
);
