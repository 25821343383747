import { call, put, select, takeLatest } from 'redux-saga/effects';
import { DefaultApi } from '../../../generated-sources/openapi';
import { cancelLogin, completeLogin, failToLogin } from './actions';
import actionTypes from './actionTypes';
import { getIsLoggedIn } from './selectors';

function* loginUseCase(apiClient: DefaultApi, action: any) {
  const { code, state } = action.payload;
  const isLoggedIn = yield select(getIsLoggedIn);
  if (isLoggedIn) {
    yield put(cancelLogin());
    return;
  }
  const res = yield call([apiClient, 'lineLogin'], {
    code,
    state,
  });
  if (res && res.status && res.status === 200) {
    yield put(completeLogin(res.data));
  } else {
    yield put(failToLogin());
  }
}

export default function* authSaga(apiClient: DefaultApi) {
  yield takeLatest(actionTypes.LOGIN_REQUEST, loginUseCase, apiClient);
}
