import { DateTime } from 'luxon';
import { createSelector } from 'reselect';
import { PurchaseProperties } from '../../../generated-sources/openapi';
import { RootState } from '../rootReducer';
import { State as UserState } from './reducer';

export const userSelector = (state: RootState): UserState => state.user;

export const getUser = createSelector<RootState, UserState, UserState>(
  userSelector,
  user => user,
);

export const getId = createSelector<RootState, UserState, UserState['id']>(
  userSelector,
  user => user && user.id,
);

export const getIsRegistered = createSelector<RootState, UserState, boolean>(
  userSelector,
  user => Boolean(user && user.name),
);

export const getPurchases = createSelector<
  RootState,
  UserState,
  UserState['purchases']
>(
  userSelector,
  user => (user && user.purchases) || [],
);

export const getActivePurchases = createSelector<
  RootState,
  UserState['purchases'],
  PurchaseProperties[]
>(
  getPurchases,
  purchases =>
    purchases.filter((purchase: PurchaseProperties) => {
      const endDate = DateTime.fromISO(purchase.startDate).plus({ months: 1 });
      return endDate >= DateTime.local();
    }),
);
