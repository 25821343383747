import { combineReducers } from 'redux';
import authReducer, { State as AuthState } from './auth/reducer';
import signUpDialogReducer, {
  State as SignUpDialogState,
} from './signUpDialog/reducer';
import spinnerReducer, { State as SpinnerState } from './spinner/reducer';
import userReducer, { State as UserState } from './user/reducer';

export interface RootState {
  auth: AuthState;
  signUpDialog: SignUpDialogState;
  spinner: SpinnerState;
  user: UserState;
}

export default combineReducers<RootState>({
  auth: authReducer,
  signUpDialog: signUpDialogReducer,
  spinner: spinnerReducer,
  user: userReducer,
});
