import authActionTypes from '../auth/actionTypes';
import userActionTypes from '../user/actionTypes';

export const initialState: State = {
  open: false,
};

export interface State {
  open: boolean;
}
type Action = any;

export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case authActionTypes.LOGIN_REQUEST:
    case userActionTypes.PURCHASE_REQUEST:
    case userActionTypes.PURCHASE_SAMPLE_REQUEST: {
      return { ...state, open: true };
    }
    case authActionTypes.LOGIN_CANCEL:
    case authActionTypes.LOGIN_COMPLETE:
    case authActionTypes.LOGIN_FAIL:
    case userActionTypes.PURCHASE_COMPLETE:
    case userActionTypes.PURCHASE_FAIL:
    case userActionTypes.PURCHASE_SAMPLE_COMPLETE:
    case userActionTypes.PURCHASE_SAMPLE_FAIL: {
      return { ...state, open: false };
    }
    default:
      return state;
  }
};
