import {
  UserProperties,
  UserUpdateRequest,
} from '../../../generated-sources/openapi';
import actionTypes from './actionTypes';

export const completeGet = (params: UserProperties) => ({
  payload: params,
  type: actionTypes.GET_COMPLETE,
});
export const completePurchase = (params: UserProperties) => ({
  payload: params,
  type: actionTypes.PURCHASE_COMPLETE,
});
export const completePurchaseSample = (params: UserProperties) => ({
  payload: params,
  type: actionTypes.PURCHASE_SAMPLE_COMPLETE,
});
export const completeUpdate = (params: UserProperties) => ({
  payload: params,
  type: actionTypes.UPDATE_COMPLETE,
});

export const failToGet = () => ({
  type: actionTypes.GET_FAIL,
});
export const failToPurchase = () => ({
  type: actionTypes.PURCHASE_FAIL,
});
export const failToPurchaseSample = () => ({
  type: actionTypes.PURCHASE_SAMPLE_FAIL,
});
export const failToUpdate = () => ({
  type: actionTypes.UPDATE_FAIL,
});

export const get = () => ({
  type: actionTypes.GET_REQUEST,
});
export const purchase = ({
  cb,
  courseId,
  memo,
  startDate,
  stripeToken,
}: {
  cb: () => void;
  courseId: 1 | 2 | 3;
  memo: object[];
  startDate: string;
  stripeToken: string;
}) => ({
  payload: { cb, courseId, memo, startDate, stripeToken },
  type: actionTypes.PURCHASE_REQUEST,
});
export const purchaseSample = ({
  cb,
  stripeToken,
}: {
  cb: () => void;
  stripeToken: string;
}) => ({
  payload: { cb, stripeToken },
  type: actionTypes.PURCHASE_SAMPLE_REQUEST,
});
export const update = ({
  cb,
  params,
}: {
  cb: () => void;
  params: UserUpdateRequest;
}) => ({
  payload: { cb, params },
  type: actionTypes.UPDATE_REQUEST,
});
