import React from 'react';
import { Provider } from 'react-redux';
import {
  applyMiddleware,
  compose,
  createStore as reduxCreateStore,
} from 'redux';
import { createLogger } from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import Loading from '../components/Loading';
import rootReducer from './rootReducer';
import sagas from './sagas';

const isDev = process.env.NODE_ENV === 'development';

const reduxLogger = createLogger({
  collapsed: true,
  level: 'info',
});
const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  blacklist: ['spinner'],
  key: 'root',
  storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const enhancer = isDev
  ? compose(applyMiddleware(sagaMiddleware, reduxLogger))
  : applyMiddleware(sagaMiddleware);

const store = reduxCreateStore(persistedReducer, {}, enhancer);
sagaMiddleware.run(sagas);
const persistor = persistStore(store);

export const PersistedReduxWrapper: React.FC = ({ children }) => (
  <Provider store={store}>
    <PersistGate loading={<Loading />} persistor={persistor}>
      {children}
    </PersistGate>
  </Provider>
);

export const ReduxWrapper: React.FC = ({ children }) => (
  <Provider store={store}>{children}</Provider>
);
