import React from 'react';
import EventListener from 'react-event-listener';
import LayoutContext from '../contexts/LayoutContext';

const getIsMobile = (width: number) => width <= 768;

const LayoutProvider: React.FC = ({ children }) => {
  const [isMobile, setIsMobile] = React.useState<boolean>(() => {
    if (typeof window !== 'undefined') {
      return getIsMobile(window.innerWidth);
    }
    return false;
  });
  const handleResize = React.useCallback(() => {
    const updated = getIsMobile(window.innerWidth);
    if (isMobile !== updated) {
      setIsMobile(updated);
    }
  }, [isMobile]);
  React.useEffect(() => handleResize(), [handleResize]);
  return (
    <LayoutContext.Provider value={{ isMobile }}>
      <EventListener target="window" onResize={handleResize} />
      {children}
    </LayoutContext.Provider>
  );
};

export default LayoutProvider;
