import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import React from 'react';

const Loading: React.FC = () => (
  <Dialog open style={{ height: '100%', width: '100%' }}>
    <Paper
      style={{
        alignItems: 'center',
        display: 'flex',
        height: '150px',
        justifyContent: 'center',
        width: '150px',
      }}
    >
      <CircularProgress disableShrink />
    </Paper>
  </Dialog>
);

export default Loading;
